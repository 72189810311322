import React, { useState } from 'react';
import { Steps } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { browserUtils } from 'tds-common-fe';
import styles from './SetUp.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../types';
import './index.global.styl';
import SetUpAuthenticator from './SetUpAuthenticator';
import Complete from './Complete';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA, SetUpCategories } from '../../../../analytics/analyticsConstants';
import { getTwoFASetUpRoute, SetUpFlowStage } from '../../../Routes/urls';
import SetUpVerificationCode from './SetUpVerificationCode';
import SetUpRecovery from './SetUpRecovery';
import ErrorTwoFA from '../shared/ErrorTwoFA';

const SET_UP_FLOW_STEP: Record<SetUpFlowStage, number> = {
    [SetUpFlowStage.setUpAuthenticator]: 0,
    [SetUpFlowStage.verificationCode]: 1,
    [SetUpFlowStage.recoveryKey]: 2,
    [SetUpFlowStage.complete]: 3,
};

const SetUpTwoFA: React.FunctionComponent = () => {
    const { setUpStage = SetUpFlowStage.setUpAuthenticator } = useParams<{ setUpStage: SetUpFlowStage }>();
    const history = useHistory();
    const [showError, setShowError] = useState(false);
    const isMobile = browserUtils.isMobileBrowser();

    const SetUpFlowStageMap: Record<SetUpFlowStage, { title: FormatIDs; content: React.ReactNode }> = {
        [SetUpFlowStage.setUpAuthenticator]: {
            title: '2FA.SetUp.Authenticator.Stages.SetUpAuthenticator',
            content: (
                <SetUpAuthenticator
                    setShowError={() => setShowError(true)}
                    onClick={() => {
                        analytics.logSetUpTwoFA(SetUpCategories.setUpAuthenticator, ButtonsTwoFA.continue);
                        history.push({
                            pathname: getTwoFASetUpRoute(SetUpFlowStage.verificationCode),
                            search: location.search,
                        });
                    }}
                />
            ),
        },
        [SetUpFlowStage.verificationCode]: {
            title: '2FA.SetUp.Authenticator.Stages.VerificationCode',
            content: <SetUpVerificationCode />,
        },
        [SetUpFlowStage.recoveryKey]: {
            title: '2FA.SetUp.Authenticator.Stages.RecoveryKey',
            content: <SetUpRecovery setShowError={() => setShowError(true)} />,
        },
        [SetUpFlowStage.complete]: {
            title: '2FA.SetUp.Authenticator.Stages.Complete',
            content: <Complete />,
        },
    };

    return (
        <div className={styles.base_container}>
            <Steps direction="horizontal" current={SET_UP_FLOW_STEP[setUpStage as SetUpFlowStage]}>
                {Object.values(SetUpFlowStageMap).map((stage) => (
                    <Steps.Step key={stage.title} title={isMobile ? '' : <FormattedMessage id={stage.title} />} />
                ))}
            </Steps>
            {showError ? <ErrorTwoFA /> : <>{SetUpFlowStageMap[setUpStage as SetUpFlowStage].content}</>}
        </div>
    );
};

export default SetUpTwoFA;
