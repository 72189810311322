import { useEffect, useRef } from 'react';
import postMessenger from '../../../../utils/PostMessenger';

interface Params {
    minHeight?: number;
    isReady?: boolean;
}

export const usePostPageHeight = (params: Params = {}) => {
    const { minHeight = 500, isReady = true } = params;

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isReady) {
            return;
        }
        const height = containerRef.current?.scrollHeight ?? 0;
        const scrollFix = scrollRef.current ? scrollRef.current.scrollHeight - scrollRef.current.clientHeight : 0;
        postMessenger.postPageHeight(Math.max(height + scrollFix, minHeight));
    }, [minHeight, isReady]);

    return { containerRef, scrollRef };
};
