export interface LoginResponse {
    accessToken: string;
    expiresAt: number;
    lastVisitedCompanyID: string;
    refreshToken: string;
    tokenType: string;
}

export interface SignUpParams {
    username?: string;
    password?: string;
    passwordConfirm?: string;
    countryCode?: string;
    inviteID?: string;
    captcha?: string;
}

export interface SocialSignupParams {
    countryCode: string;
    social: Socials;
    inviteID?: string;
}

export interface SetUpProfileParams {
    companyName: string;
    firstName: string;
    lastName: string;
}

export interface ResendEmailVerificationParams {
    email: string;
}

export interface ForgotPasswordParams {
    username: string;
}

export interface ResetPasswordParams {
    token: string;
    username: string;
    password: string;
    passwordConfirm: string;
}

export interface ChangePasswordParams {
    passwordOld: string;
    password: string;
    passwordConfirm: string;
}

export enum ProductType {
    Inspect = 'inspect',
    Workspace = 'workspace',
    Proceq = 'proceq',
}

export enum FormSteps {
    email,
    password,
}

export enum Socials {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    APPLE = 'apple',
}

export enum AuthType {
    Login = 'login',
    SignUp = 'signup',
    ConfirmPassword = 'confirmPassword',
}
