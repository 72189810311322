import React, { useEffect } from 'react';
import { Route, Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { values } from 'lodash';
import * as appUtils from '../../utils/appUtils';
import * as urls from './urls';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import LanguageThemeRoute from './LanguageThemeRoute';
import AuthRoutes from './AuthRoutes';
import AppRoutes from './AppRoutes';
import NotFound from './NotFound';
import SwitchWrapper from './SwitchWrapper';
import AppRedirection from '../Authentication/AppRedirection';
import TwoFARoutes from './TwoFARoutes';
import MultipleDevicesRoutes from './MultipleDevicesRoutes';

const history = createBrowserHistory();

const authRoutes = values(urls.AUTH_ROUTES);

const Routes: React.FunctionComponent = () => {
    useEffect(() => {
        appUtils.postAppData(appUtils.appDataTypes.initialized, true);
    }, []);

    return (
        <Router history={history}>
            <LanguageThemeRoute path="*">
                <SwitchWrapper>
                    <AnalyticsRoute
                        exact
                        titleFormatIDs={[]}
                        path={urls.AUTH_ROUTES.APP_REDIRECTION}
                        component={AppRedirection}
                    />
                    <Route path={authRoutes} component={AuthRoutes} />
                    <Route path={urls.TWO_FA_ROUTE_BASE} component={TwoFARoutes} />
                    <Route path={urls.MULTIPLE_DEVICES_ROUTE_BASE} component={MultipleDevicesRoutes} />
                    <Route path={urls.APP_ROUTE_BASE} component={AppRoutes} />
                    <AnalyticsRoute path={urls.NOT_FOUND} titleFormatIDs={['NavTitle.NotFound']}>
                        <NotFound />
                    </AnalyticsRoute>
                    <Route exact path="/">
                        <Redirect to={urls.AUTH_ROUTES.LOGIN} />
                    </Route>
                </SwitchWrapper>
            </LanguageThemeRoute>
        </Router>
    );
};

export default Routes;
