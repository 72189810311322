import React, { useCallback, useRef } from 'react';
import { routeUtils } from 'tds-common-fe';
import VerificationCode, { VerificationCodeFlow } from '../shared/VerificationCode';
import styles from '../Login/Login.styl';
import URL from '../../../../api/urls';
import { getScreeningEagleHeaders } from '../../../../api/headerUtils';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA, SetUpCategories } from '../../../../analytics/analyticsConstants';

const SetUpVerificationCode = () => {
    const htmlFormRef = useRef<HTMLFormElement>(null);
    const { error, ...queryParams } = routeUtils.parseQuery<{ error?: string }>(location.search);

    const actionURL = routeUtils.makeQueryPath(URL.TWO_FA_SET_UP_VERIFY, {
        ...getScreeningEagleHeaders(),
        ...queryParams,
    });

    const handleSubmit = useCallback((code: string) => {
        analytics.logSetUpTwoFA(SetUpCategories.verificationCode, ButtonsTwoFA.verify);
        const form = htmlFormRef.current;
        if (form !== null) {
            const codeInput: HTMLInputElement | null = form.querySelector('[name="code"]');
            if (codeInput) {
                codeInput.value = code;
            }
            form.submit();
        }
    }, []);

    return (
        <>
            <VerificationCode
                onClick={handleSubmit}
                verificationCodeFlow={VerificationCodeFlow.setup}
                errorFormatID="2FA.SetUp.VerificationCode.FailedSetUp"
            />
            <form ref={htmlFormRef} action={actionURL} method="POST" className={styles.hidden_form}>
                <input name="code" type="password" autoComplete="off" tabIndex={-1} />
            </form>
        </>
    );
};

export default SetUpVerificationCode;
