import { routeUtils } from 'tds-common-fe';
import { get, post, thunkPost } from './apiService';
import urls from './urls';
import { APIResponse, LoginResponse } from '../types';
import * as authenticationActions from '../actions/authentication';
import {
    ChangePasswordParams,
    ForgotPasswordParams,
    ResendEmailVerificationParams,
    ResetPasswordParams,
    SignUpParams,
    SocialSignupParams,
} from '../types/authentication';
import { getScreeningEagleHeaders } from './headerUtils';

export function login(identity: string, password: string) {
    type Response = LoginResponse;
    const params = { identity, password, grantType: 'password' };
    return thunkPost<Response>(urls.LOGIN, { params }).then(({ response, dispatch }) => {
        dispatch(authenticationActions.loginSuccess(response));
    });
}

export function signUp(params: SignUpParams) {
    return post<any>(urls.SIGNUP, { params: { ...params } });
}

export function signUpSocial(params: SocialSignupParams) {
    return post<any>(urls.SIGNUP_SOCIAL_LOGIN, { params: { ...params } });
}

export function emailVerification(token: string) {
    const url = urls.EMAIL_VERIFICATION.replace('{token}', token);
    return post<{}>(url);
}

export function resendEmailVerification(params: ResendEmailVerificationParams) {
    return post<{}>(urls.RESEND_EMAIL_VERIFICATION, { params: { ...params } });
}

export function forgotPassword(params: ForgotPasswordParams) {
    return post<{}>(urls.PASSWORD_FORGOT, { params: { ...params } });
}

export function resetPassword(params: ResetPasswordParams) {
    return post<{}>(urls.PASSWORD_RESET, { params: { ...params } });
}

export function changePassword(params: ChangePasswordParams) {
    return post<{}>(urls.PASSWORD_CHANGE, { params: { ...params } });
}

interface TwoFASetUpCodeResponse {
    qrCode: string;
    code: string;
}

export function getTwoFASetUpCode() {
    return post<APIResponse<TwoFASetUpCodeResponse>>(urls.TWO_FA_SET_UP_CODE);
}

interface TwoFARecoveryKeyResponse {
    key: string;
}

export function getTwoFASetUpRecoveryKey() {
    return get<APIResponse<TwoFARecoveryKeyResponse[]>>(urls.TWO_FA_SET_UP_RECOVERY_KEY);
}

export function getTwoFARecoveryKey(confirmCode: string) {
    return get<APIResponse<TwoFARecoveryKeyResponse[]>>(urls.TWO_FA_RECOVERY_KEY, { params: { confirmCode } });
}

export function confirmSignIn(confirmCode: string, token?: string) {
    const url = routeUtils.makeQueryPath(urls.CONCURRENT_CONFIRM, {
        confirm_code: confirmCode,
        ...getScreeningEagleHeaders(),
    });
    return post(url, token ? { config: { headers: { Authorization: token } } } : undefined);
}
