import React from 'react';
import { useHistory } from 'react-router-dom';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import styles from '../TwoFAStyles.styl';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { getTwoFASetUpRoute, SetUpFlowStage } from '../../../Routes/urls';
import analytics from '../../../../analytics/index';
import { EventName } from '../../../../analytics/analyticsConstants';

const EnableTwoFA: React.FunctionComponent = () => {
    const history = useHistory();
    return (
        <div className={classNames(wrapperStyles.content_wrapper, wrapperStyles.input_block)}>
            <div className={styles.header}>
                <FormattedMessage id="2FA.Enable.Title" />
            </div>
            <div className={styles.body_text}>
                <FormattedMessage id="2FA.Enable.Body" />
            </div>
            <AnalyticsButton
                className={styles.primary_button_container}
                type="primary"
                onClick={() => {
                    analytics.logEventWithName(EventName.ENABLE_2FA);
                    history.push({
                        pathname: getTwoFASetUpRoute(SetUpFlowStage.setUpAuthenticator),
                        search: location.search,
                    });
                }}
                size="large"
            >
                <span className={styles.primary_button_text}>
                    <FormattedMessage id="2FA.Enable.Title" />
                </span>
            </AnalyticsButton>
        </div>
    );
};

export default EnableTwoFA;
